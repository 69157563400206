import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { PageNotFoundComponent } from './shared/views/page-not-found/page-not-found.component';
import { NotAuthorizedComponent } from './shared/views/not-authorized/not-authorized.component';
import { PermissionGuard } from './core/guards/permission.guard';
import { Permission } from './shared/models/vcall/permission';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LoggedOutComponent } from './shared/components/logged-out/logged-out.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./appointments/appointments.module').then(
        (m) => m.AppointmentsModule
      ),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    data: {
      verifyCallZoneUser: true,
    },
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: 'appointments',
    loadChildren: () =>
      import('./appointments/appointments.module').then(
        (m) => m.AppointmentsModule
      ),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    data: {
      requiredPermission: Permission.CanAccessAppointments,
    },
  },
  {
    path: 'impersonate',
    loadChildren: () =>
      import('./impersonation/impersonation.module').then(
        (m) => m.ImpersonationModule
      ),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    // canActivate: [MsalGuard, AuthorizationGuard],
    data: {
      requiredPermission: Permission.CanAccessImpersonate,
      verifyCallZoneUser: true,
    },
  },
  {
    path: 'vcall',
    loadChildren: () =>
      import('./vcall/vcall.module').then((m) => m.VcallModule),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    data: { requiredPermission: Permission.CanAccessViewVcallScreen },
  },
  // {
  //   path: 'recall',
  //   loadChildren: './recall/recall.module#RecallModule',
  //   canActivate: [MsalGuard, AuthorizationGuard],
  // },

  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    data: { requiredPermission: Permission.CanAccessAdmin },
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '*',
    component: PageNotFoundComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: 'developer',
    loadChildren: () =>
      import('./developer/developer.module').then((m) => m.DeveloperModule),
    canActivate: [MsalGuard, AuthorizationGuard],
  },
  {
    path: 'underwriting',
    loadChildren: () =>
      import('./underwriting/underwriting.module').then(
        (m) => m.UnderwritingModule
      ),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
    data: { requiredPermission: Permission.CanAccessUnderwriting },
  },
  {
    path: 'userguide',
    loadChildren: () =>
      import('./user-guide/user-guide.module').then((m) => m.UserGuideModule),
    canActivate: [MsalGuard, AuthorizationGuard, PermissionGuard],
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}